import { fetchGet, fetchPost, fetchPostPic, fetchGetMess } from './index'
type YearTpye = {
  year: string;
  month: string;
  showYear: string;
};
import type { SpecTreeTable, ReportResType, VersionType } from '@/views/Closing/type'
enum API {
  HomePageLock = '/pcapi/fc/homepage/toggleLock',
  HomePageNewMake = '/pcapi/fc/homepage/initMonthly',
  PMTInfo = '/pcapi/fc/pmt/rule/info',
  PMTInfoSave = '/pcapi/fc/pmt/rule/save',
  PreparationKufri = '/pcapi/forecast/dataPreparation/collectKufriData',
  PreparationProgram = '/pcapi/forecast/program/init',
  PreparationResult = '/pcapi/forecast/dataPreparation/collectInterfaceData/result',
  PreparationCalculation = '/pcapi/forecast/dataPreparation/calculate',
  ForecastOverview = '/pcapi/forecast/blueInvoice/overview/get',
  ForecastProgrambase = '/pcapi/forecast/program/info',
  ForecastProgrambaseSave = '/pcapi/forecast/program/batchSave',
  ForecastInterest = '/pcapi/forecast/interest/getInterestInfo',
  ForecastInterestSave = '/pcapi/forecast/interest/save',
  ForecastBlueInvoice = '/pcapi/forecast/blueInvoice/overview/getSubInfo',
  ForecastBlueInvoiceSave = '/pcapi/forecast/blueInvoice/save'
}

// 获取PMT Rule
export const getPmtRoleInfo = () => {
  return fetchGet(API.PMTInfo)
}

// 保存PMT Rule
export const savePmtRoleInfo = (params = {}) => {
  return fetchPost(API.PMTInfoSave, params)
}
// 获取当前年月
export const getCurrentYear = (): Promise<YearTpye> => {
  return fetchGet('/pcapi/fc/homepage/currYear')
}
// 获取make基本信息
export const getMakeInfos = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/fc/homepage/monthlyClosingByMake', { params })
}
//  homepage lock
export const getLock = (params = {}): Promise<any> => {
  return fetchGet(API.HomePageLock, { params })
}
// 获取make基本信息
export const getNewMake = (params = {}): Promise<any> => {
  return fetchGet(API.HomePageNewMake, { params })
}

export const importData = (params = {}): Promise<any> => {
  return fetchGet(API.PreparationKufri, { params })
}
// data prepartion program
export const initProgramInfo = (params: any): Promise<any> => {
  return fetchPostPic(`${API.PreparationProgram}?mcId=${params}`)
}
// 获取 data pre 信息 
export const getDataPre = (params = {}): Promise<any> => {
  return fetchGet(API.PreparationResult, { params })
}
// 计算按钮接口
export const getCalculate = (params = {}): Promise<any> => {
  return fetchGetMess(API.PreparationCalculation, { params })
}
// 获取 forecast Overview信息
export const getOverview = (params = {}): Promise<any> => {
  return fetchGet(API.ForecastOverview, { params })
}
// 获取 forecast programbase信息
export const getTableInfo = (params = {}): Promise<any> => {
  return fetchGet(API.ForecastProgrambase, { params })
}
// 保存 forecast programbase信息
export const saveTableInfo = (params = {}): Promise<any> => {
  return fetchPost(API.ForecastProgrambaseSave, params)
}
// 获取interest数据
export const getInterestInfo = (params = {}): Promise<any> => {
  return fetchGet(API.ForecastInterest, { params })
}
// 保存 interest
export const saveInterestInfo = (params = {}) => {
  return fetchPost(API.ForecastInterestSave, params)
}
// 获取Blue Invoice信息
export const getSubOverview = (params = {}): Promise<any> => {
  return fetchGet(API.ForecastBlueInvoice, { params })
}
// 保存Blue Invoice信息
export const saveBlueInvoiceInfo = (params: any[]): Promise<any> => {
  return fetchPost(API.ForecastBlueInvoiceSave, params)
}
// Specil Adj Sub Info
export const getSubInfo = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/api/v1/forecastAllocation/overview/getSubInfo', { params })
}

// Specil Adj Brand
export const getSpecBrand = (params = {}): Promise<string[]> => {
  return fetchGet('/pcapi/api/v1/forecastAllocation/getBrandCategory', { params })
}

// Specil Adj Topic & Name
export const getTopicName = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/api/v1/forecastAllocation/getNameAndTopicList', { params })
}

// Specil Adj TreeTable
export const getTreeTable = (params = {}): Promise<SpecTreeTable> => {
  return fetchGet('/pcapi/api/v1/forecastAllocation/searchTree', { params })
}

// Specil Adj AdjustmentNo
export const getAdjustmentNo = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/api/v1/forecastAllocation/overview/getMaxAdjustmentNo', { params })
}

// Specil Adj Save
export const postSaveData = (params = {}): Promise<any> => {
  return fetchPost('/pcapi/api/v1/forecastAllocation/save', params)
}
// Report l1Structure
export const getl1Structure = (): Promise<string[]> => {
  return fetchGet('/pcapi/forecast/reportData/l1Structure')
}
// Report 1 报表
export const getReportOverview = (params = {}): Promise<ReportResType> => {
  return fetchGet('/pcapi/forecast/reportData/getOverview', { params })
}
// Report 2 报表
export const getReport2 = (params = {}): Promise<ReportResType> => {
  return fetchGet('/pcapi/forecast/reportData/getOverviewByBrand', { params })
}
// Report 3 报表
export const getReport3 = (params = {}): Promise<any> => {
  return fetchGet('/pcapi/forecast/reportData/getOverviewByBrandAndL1', { params })
}
// Report 4 报表
export const getReport4 = (params = {}): Promise<ReportResType> => {
  return fetchGet('/pcapi/forecast/reportData/getOverviewByDemoCar', { params })
}
// final view button save(新接口 2021/10/29)
export const postFinalSave = (params: number): Promise<any> => {
  return fetchPostPic(`/pcapi/forecast/reportData/saveWipVersion2FianlVersion?mcId=${params}`)
}
export const seachVersion = (params = {}): Promise<VersionType> => {
  return fetchGet('/pcapi/forecast/reportData/searchReportVersion', { params })
}
// final view button enable(新接口 2021/10/29)
export const postFinalEnable = (params: number): Promise<any> => {
  return fetchPostPic(`/pcapi/forecast/reportData/enable?mcId=${params}`)
}
// final view button edit(新接口 2021/10/29)
export const postFinalEdit = (params: number): Promise<any> => {
  return fetchPostPic(`/pcapi/forecast/reportData/getMcIds?mcId=${params}`)
}
// 获取报表brand
export const getBrandList = (params = {}): Promise<string[]> => {
  return fetchGet('/pcapi/forecast/reportData/brandList', { params })
}
// 生成报表
export const generateReport = (params = {}): Promise<any> => {
  return fetchPostPic('/pcapi/forecast/reportData/generateReport', params)
}